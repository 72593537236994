<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="12">
        <v-card>
          <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
            <v-form lazy-validation @submit.prevent="handleSubmit(save)">
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="12" lg="6">
                      <validation-provider rules="required|max:200" v-slot="{ errors }" name="organisation">
                        <v-text-field
                          v-model="name"
                          name="name"
                          autofocus
                          clearable
                          counter="200"
                          :label="$t('organisationsPage.name')"
                          :error-messages="errors"></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" sm="6" md="12" lg="6">
                      <validation-provider rules="required|max:200" v-slot="{ errors }" name="address">
                        <v-text-field
                          v-model="address"
                          name="address"
                          clearable
                          counter="200"
                          :label="$t('organisationsPage.address')"
                          :error-messages="errors"></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" sm="6" md="12" lg="6">
                      <validation-provider
                        :rules="{
                          max: 200,
                          required: true,
                          regex: /^\+?[0-9\s\-()]+$/,
                        }"
                        v-slot="{ errors }"
                        name="phone_number">
                        <v-text-field
                          type="tel"
                          name="phone"
                          v-model="phone_number"
                          clearable
                          counter="200"
                          :label="$t('organisationsPage.phoneNumber')"
                          :error-messages="errors"></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" sm="6" md="12" lg="6">
                      <validation-provider
                        rules="required|email|max:200"
                        v-slot="{ errors }"
                        name="email"
                        ref="emailRef">
                        <v-text-field
                          type="email"
                          name="email"
                          v-model="email"
                          clearable
                          counter="200"
                          :label="$t('organisationsPage.email')"
                          :error-messages="errors"></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" sm="6" md="12" lg="6">
                      <validation-provider rules="max:200" v-slot="{ errors }" name="vat" ref="vatRef">
                        <v-text-field
                          v-model="vat"
                          name="vat"
                          clearable
                          counter="200"
                          :label="$t('organisationsPage.vat')"
                          :error-messages="errors"></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" sm="6" md="12" lg="6">
                      <validation-provider rules="max:200" v-slot="{ errors }" name="company_id" ref="companyIdRef">
                        <v-text-field
                          v-model="company_id"
                          name="company_id"
                          clearable
                          counter="200"
                          :label="$t('organisationsPage.companyId')"
                          :error-messages="errors"></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" sm="6" md="12" lg="6">
                      <validation-provider rules="max:200" v-slot="{ errors }" name="warrant_number">
                        <v-text-field
                          v-model="warrant_number"
                          name="warrant_number"
                          clearable
                          counter="200"
                          :label="$t('organisationsPage.warrantNumber')"
                          :error-messages="errors"></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" sm="6" md="12" lg="6">
                      <validation-provider rules="required" v-slot="{ errors }" name="time_zone">
                        <v-autocomplete
                          v-model="timezone"
                          :items="timeZones"
                          clearable
                          item-text="name"
                          item-value="name"
                          :label="$t('organisationsPage.timeZone')"
                          required
                          :no-data-text="$t('select.noDataAvailable')"
                          @focus="$event.target.click()"
                          :error-messages="errors"></v-autocomplete>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" sm="6" md="12" lg="6">
                      <validation-provider rules="required" v-slot="{ errors }" name="language">
                        <v-autocomplete
                          name="language"
                          clearable
                          item-text="lang"
                          item-value="abbr"
                          v-model="language"
                          :items="languageOptions"
                          :label="$t('organisationsPage.language')"
                          prepend-icon="mdi-web"
                          :error-messages="errors">
                          <template v-slot:append>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" class="ml-2 d-none d-sm-block">mdi-help-circle</v-icon>
                              </template>
                              <span>{{ $t('organisationsPage.languageTooltip') }}</span>
                            </v-tooltip>
                          </template>
                        </v-autocomplete>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" sm="6" md="12" lg="6">
                      <validation-provider rules="image|size:4000" v-slot="{ errors }" name="image">
                        <v-file-input
                          v-model="image"
                          name="image"
                          accept="image/jpg, image/jpeg, image/png"
                          counter
                          :label="$t('organisationsPage.image')"
                          prepend-icon="mdi-camera"
                          show-size
                          small-chips
                          :error-messages="errors">
                          <template v-slot:append>
                            <v-icon v-if="logo" @click="onDeleteLogo()">mdi-delete</v-icon>
                          </template>
                        </v-file-input>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" sm="6" md="12" lg="6" class="d-flex align-center justify-start">
                      <v-btn @click="openMapModal">
                        <v-icon left>mdi-map-marker</v-icon>
                        {{
                          hq_lat && hq_lng
                            ? $t('organisationsPage.changeLocation')
                            : $t('organisationsPage.selectLocation')
                        }}
                      </v-btn>

                      <!-- Modal -->
                      <v-dialog v-model="mapModalVisible" max-width="700px">
                        <v-card>
                          <v-card-title class="info title white--text font-weight-bold">
                            <span class="text-h6">
                              {{
                                hq_lat && hq_lng
                                  ? $t('organisationsPage.changeLocation')
                                  : $t('organisationsPage.selectLocation')
                              }}
                            </span>
                            <v-spacer />
                            <v-icon class="mdi mdi-close" style="color: white" @click="closeMapModal"></v-icon>
                          </v-card-title>
                          <v-card-text class="px-0">
                            <!-- Google Map Component -->
                            <organisation-location ref="googleMap" :hq_lat="hq_lat" :hq_lng="hq_lng" />
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn class="primary cancel" @click="closeMapModal">{{ $t('buttons.cancel') }}</v-btn>
                            <v-btn class="primary" @click="confirmLocation">{{ $t('buttons.confirm') }}</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="d-flex">
                <v-col cols="6">
                  <v-menu open-on-hover bottom offset-y ref="menu">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" @click="toggleMenu" id="advanced-settings-step-0">
                        {{ $t('buttons.settings') }}
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item to="/advanced-settings">
                        <v-list-item-title>
                          {{ $t('buttons.advancedSettings') }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="showCancelSubscriptionModal">
                        <v-list-item-title style="color: red">
                          {{ $t('buttons.cancelSubscription') }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
                <v-col cols="6" class="text-end">
                  <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
                </v-col>
              </v-card-actions>
            </v-form>
          </validation-observer>
        </v-card>
      </v-col>

      <v-dialog v-model="cancelSubscriptionModal" max-width="650">
        <v-card>
          <v-card-title class="info title white--text font-weight-bold">
            {{ $t('organisationsPage.cancelSubscriptionTitle') }}
            <v-spacer />
            <v-icon class="mdi mdi-close" style="color: white" @click="closeCancelSubscriptionModal"></v-icon>
          </v-card-title>
          <v-card-text>
            <v-container>
              <p>
                {{ $t('organisationsPage.cancelSubscrMessage') }}
              </p>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn class="primary red" text @click="confirmCancelSubscription">
              {{ $t('buttons.yes') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-col cols="12" lg="6" md="6" sm="12">
        <div class="row">
          <div class="col-12 text-center">
            <img v-if="logo" class="mx-auto org-image org-image__big" :src="logo" />
            <v-img v-else :src="require('/src/assets/placeholder.png')" class="mx-auto org-image org-image__big">
            </v-img>
          </div>
          <div class="col-12 text-center org-name font-weight-bold">
            {{ preview.name }}
          </div>
          <!-- <div class="col-12 text-center org-address">
            <span class="font-weight-bold">{{ $t('organisationsPage.address') }}</span
            >: {{ preview.address ? preview.address : '-' }}
          </div> -->
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 text-center d-flex flex-column">
            <div class="font-weight-bold">{{ $t('organisationsPage.address') }}</div>
            <div>{{ preview.address ? preview.address : '-' }}</div>
          </div>
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 text-center d-flex flex-column">
            <div class="font-weight-bold">{{ $t('organisationsPage.affiliateId') }}</div>
            <div>{{ preview.affiliate_id ? preview.affiliate_id : '-' }}</div>
          </div>
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 text-center d-flex flex-column">
            <div class="font-weight-bold">{{ $t('organisationsPage.phoneNumber') }}</div>
            <div>{{ preview.phone_number ? preview.phone_number : '-' }}</div>
          </div>
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 text-center d-flex flex-column">
            <div class="font-weight-bold">{{ $t('organisationsPage.email') }}</div>
            <div>{{ preview.email ? preview.email : '-' }}</div>
          </div>
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 text-center d-flex flex-column">
            <div class="font-weight-bold">{{ $t('organisationsPage.vat') }}</div>
            <div>{{ preview.vat ? preview.vat : '-' }}</div>
          </div>
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 text-center d-flex flex-column">
            <div class="font-weight-bold">{{ $t('organisationsPage.companyId') }}</div>
            <div>{{ preview.company_id ? preview.company_id : '-' }}</div>
          </div>
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 text-center d-flex flex-column">
            <div class="font-weight-bold">{{ $t('organisationsPage.warrantNumber') }}</div>
            <div>{{ preview.warrant_number ? preview.warrant_number : '-' }}</div>
          </div>
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 text-center d-flex flex-column">
            <div class="font-weight-bold">{{ $t('organisationsPage.timeZone') }}</div>
            <div>{{ preview.timezone ? preview.timezone : '-' }}</div>
          </div>
          <div class="col-12 col-sm-6 col-md-12 col-lg-6 text-center d-flex flex-column">
            <div class="font-weight-bold">{{ $t('organisationsPage.language') }}</div>
            <div>{{ preview.language ? preview.language : '-' }}</div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="emailChangeModal" max-width="650">
      <v-card>
        <v-card-title class="info title white--text font-weight-bold">
          {{ $t('usersPage.emailChanged') }}
          <v-spacer />
          <v-icon class="mdi mdi-close" style="color: white" @click="closeEmailChangeModal"></v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <p>
              {{ $t('usersPage.verificationEmailSent') }}
            </p>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="primary" text @click="closeEmailChangeModal">
            {{ $t('buttons.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteLogoModal" max-width="650">
      <v-card>
        <v-card-title class="info title white--text font-weight-bold">
          {{ $t('organisationsPage.deleteImageTitle') }}
          <v-spacer />
          <v-icon class="mdi mdi-close" style="color: white" @click="closeDeleteLogoModal"></v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <p>
              {{ $t('organisationsPage.deleteImageMessage') }}
            </p>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="primary" text @click="deleteLogo">{{ $t('buttons.yes') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="tourStyle">
      <v-tour name="advancedSettingsTour" :steps="stepsTour" :callbacks="myCallbacks" :options="options"></v-tour>
    </div>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import state from '@/store';
import router from '@/router';
import { languageOptions } from '@/mixins/language-options';
import { getAllTourSteps, advancedSettingsPageSteps } from '@/mixins/onboarding-tutorial-steps';
import { mapState } from 'vuex';
import OrganisationLocation from '@/components/organisations/OrganisationLocation.vue';
import { timeZones } from '@/mixins/time-zones';

export default {
  name: 'UserOrganisationPage',
  components: { ButtonSubmit, OrganisationLocation },
  data() {
    return {
      searchString: '',
      dialog: false,
      formValid: true,
      disableToggle: false,
      enableSave: true,
      id: null,
      name: '',
      bearerToken: '',
      address: '',
      phone_number: '',
      email: '',
      oldEmail: '',
      vat: '',
      company_id: '',
      warrant_number: '',
      timezone: '',
      language: '',
      affiliate_id: '',
      logo: '',
      image: null,
      hq_lat: null,
      hq_lng: null,
      loading: false,
      emailChangeModal: false,
      preview: {
        name: null,
        address: null,
        phone_number: null,
        email: null,
        vat: null,
        company_id: null,
        warrant_number: null,
        timezone: null,
        language: null,
        affiliate_id: null,
      },
      cancelSubscriptionModal: false,
      deleteLogoModal: false,
      languageOptions: languageOptions,
      myCallbacks: {
        onStop: this.onComplete,
        onNextStep: this.onNextStep,
        onSkip: this.onSkip,
      },
      steps: [],
      visitedStepsArray: [],
      stepsTour: [],
      window: {
        width: 0,
      },
      options: {
        labels: {
          buttonSkip: i18n.t('buttons.skip'),
          buttonPrevious: '<< ' + i18n.t('buttons.prev'),
          buttonNext: i18n.t('buttons.next') + ' >>',
          buttonStop: i18n.t('buttons.finish'),
        },
        highlight: true,
      },
      mapModalVisible: false,
      selectedLocation: null,
      timeZones: timeZones,
    };
  },
  created() {
    this.loadOrganisation();
  },
  computed: {
    ...mapState('onboardingTutorial', ['tourActive']),
    showDeleteModal() {
      return this.deleteLogoModal;
    },
  },
  methods: {
    async loadOrganisation() {
      await this.$store.dispatch('organisations/getAllOrganisations').then((res) => {
        let data = res.data[0];
        this.organisations = data;
        this.name = data.name;
        this.id = data.id;
        this.address = data.address;
        this.phone_number = data.phone_number;
        this.email = data.email;
        this.oldEmail = data.email;
        this.vat = data.vat;
        this.company_id = data.company_id;
        this.warrant_number = data.warrant_number;
        this.timezone = data.timezone;
        this.language = data.language;
        this.affiliate_id = data.affiliate_id;
        this.hq_lat = data.hq_lat;
        this.hq_lng = data.hq_lng;

        if (data.logo_path && !data.logo_path.includes('https://') && !data.logo_path.includes('http://')) {
          this.logo = (process.env.VUE_APP_BACKEND_URL + data.logo_path).replace('public', 'storage');
        } else {
          this.logo = data.logo_path ?? '';
        }

        this.makePreviewObject();

        this.setStepsAndStartTour();
      });
    },

    makePreviewObject() {
      this.preview.name = this.name;
      this.preview.address = this.address;
      this.preview.phone_number = this.phone_number;
      this.preview.email = this.email;
      this.preview.vat = this.vat;
      this.preview.company_id = this.company_id;
      this.preview.warrant_number = this.warrant_number;
      this.preview.timezone = this.timezone;
      this.preview.language = languageOptions.find((item) => item.abbr === this.language).lang;
      this.preview.affiliate_id = this.affiliate_id;
    },

    createFormData() {
      let formData = new FormData();
      if (this.image) {
        formData.append('logo', this.image);
      }
      formData.append('name', this.name);
      formData.append('address', this.address);
      formData.append('phone_number', this.phone_number);
      formData.append('email', this.email);
      formData.append('vat', this.vat ? this.vat : '');
      formData.append('company_id', this.company_id ? this.company_id : '');
      formData.append('warrant_number', this.warrant_number ? this.warrant_number : '');
      formData.append('timezone', this.timezone ? this.timezone : '');
      formData.append('language', this.language ? this.language : '');
      formData.append('hq_lat', this.selectedLocation?.lat ? this.selectedLocation?.lat : '');
      formData.append('hq_lng', this.selectedLocation?.lng ? this.selectedLocation?.lng : '');

      return formData;
    },

    async save() {
      this.enableSave = false;
      this.loading = true;
      let formData = this.createFormData();
      await this.$store
        .dispatch('organisations/saveOrganisation', formData)
        .then((res) => {
          if (
            res.data?.logo_path &&
            !res.data.logo_path.includes('https://') &&
            !res.data.logo_path.includes('http://')
          ) {
            this.logo = (process.env.VUE_APP_BACKEND_URL + res.data.logo_path).replace('public', 'storage');
          } else {
            this.logo = res.data.logo_path ?? '';
          }

          this.image = null;
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          if (this.email !== this.oldEmail) {
            this.emailChangeModal = true;
          }

          this.makePreviewObject();
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            if (
              error.response.data.errors?.vat ||
              error.response.data.errors?.company_id ||
              error.response.data.errors?.email
            ) {
              if (error.response.data.errors?.vat && error.response.data.errors?.vat[0] === 'ORGANISATION-0017') {
                this.$refs.vatRef.applyResult({
                  errors: [
                    i18n.t('customValidations.fieldUnique', {
                      field: i18n.t('names.vat'),
                    }),
                  ],
                });
              }
              if (
                error.response.data.errors?.company_id &&
                error.response.data.errors?.company_id[0] === 'ORGANISATION-0018'
              ) {
                this.$refs.companyIdRef.applyResult({
                  errors: [
                    i18n.t('customValidations.fieldUnique', {
                      field: i18n.t('names.company_id'),
                    }),
                  ],
                });
              }
              if (error.response.data.errors?.email && error.response.data.errors?.email[0] === 'ORGANISATION-0019') {
                this.$refs.emailRef.applyResult({
                  errors: [
                    i18n.t('customValidations.fieldUnique', {
                      field: i18n.t('names.email'),
                    }),
                  ],
                });
              }
            } else {
              this.$store
                .dispatch('errorMessages/errorMapper', error.response.data.message)
                .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
            }
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.enableSave = true;
          this.loading = false;
        });
    },

    closeEmailChangeModal() {
      this.emailChangeModal = false;
    },
    onDeleteLogo() {
      this.deleteLogoModal = true;
    },
    async deleteLogo() {
      await this.$store
        .dispatch('organisations/deleteOrganizationImage', { id: this.id })
        .then(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
          this.loadOrganisation();
          this.closeDeleteLogoModal();
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },
    closeDeleteLogoModal() {
      this.deleteLogoModal = false;
    },
    showCancelSubscriptionModal() {
      this.cancelSubscriptionModal = true;
    },
    closeCancelSubscriptionModal() {
      this.cancelSubscriptionModal = false;
    },

    async confirmCancelSubscription() {
      try {
        await this.$store.dispatch('subscriptionPackages/cancelPackage', {});

        this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.canceledSubscrSuccessfully'), color: 'green' });
        this.closeCancelSubscriptionModal();
        state.dispatch('auth/removeUserData');
        router.push({ name: 'Login' });
      } catch (error) {
        this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
      }
    },

    toggleMenu() {
      if (this.$refs.menu) {
        this.$refs.menu.isActive = !this.$refs.menu.isActive;
      }
    },

    openMapModal() {
      this.mapModalVisible = true;
    },
    closeMapModal() {
      this.mapModalVisible = false;
    },
    confirmLocation() {
      // Access the selected location from the map
      const location = this.$refs.googleMap.location;
      if (location) {
        this.selectedLocation = location;
      }
      this.closeMapModal();
    },

    async setStepsAndStartTour() {
      if (this.$store.state.auth.role !== 1 && !this.tourActive) {
        await this.setSteps();
        if (this.stepsTour?.length > 0) {
          this.startTour();
        }
      }
    },

    setSteps() {
      return new Promise((resolve) => {
        const visitedStepsParsed = JSON.parse(localStorage.getItem('LimoExpressUser'))?.user_tour_logs;

        if (visitedStepsParsed) {
          this.visitedStepsArray = visitedStepsParsed
            .filter((item) => item.component_id === '11')
            ?.map((item) => item.step_id);
          this.steps = advancedSettingsPageSteps();
          if (this.visitedStepsArray?.length > 0) {
            this.stepsTour = this.steps.filter((step) => !this.visitedStepsArray.includes(step.target));
          } else {
            this.stepsTour = this.steps;
          }
        } else {
          this.stepsTour = [];
          let user = this.$store.getters['auth/user'];
          user.user_tour_logs = getAllTourSteps();

          this.$store.dispatch('auth/setUser', user);
          localStorage.setItem('LimoExpressUser', JSON.stringify(user));
        }

        resolve();
      });
    },

    startTour() {
      this.$tours['advancedSettingsTour'].start();
      this.$store.dispatch('onboardingTutorial/toggleTour', true);
    },

    onComplete() {
      this.$store.dispatch('onboardingTutorial/toggleTour', false);
      this.setStepAsVisited(this.steps.pop()?.target?.split('-').pop());
    },

    onSkip() {
      this.$store.dispatch('onboardingTutorial/toggleTour', false);

      const body = {
        objects: this.steps.map((item) => ({
          component_id: '11',
          step_id: item.target,
        })),
      };

      this.$store.dispatch('onboardingTutorial/sendVisitedStep', body);
      const lastCharsOfTarget = this.steps.map((step) => step.target.slice(-1));
      lastCharsOfTarget.forEach((step) => this.updateStorage(step));
    },

    onNextStep(currentStep) {
      const currentStepId = this.stepsTour[currentStep]?.targetId;
      this.setStepAsVisited(currentStepId);
    },

    updateStorage(currentStep) {
      const newVisitedStep = {
        component_id: '11',
        step_id: `#advanced-settings-step-${currentStep}`,
      };

      let user = this.$store.getters['auth/user'];

      if (user) {
        if (user.user_tour_logs?.length > 0) {
          user.user_tour_logs.push(newVisitedStep);
        } else {
          user.user_tour_logs = [newVisitedStep];
        }
      }

      this.$store.dispatch('auth/setUser', user);
      localStorage.setItem('LimoExpressUser', JSON.stringify(user));
    },

    setStepAsVisited(step) {
      const body = {
        objects: [
          {
            component_id: '11',
            step_id: `#advanced-settings-step-${step}`,
          },
        ],
      };
      this.$store.dispatch('onboardingTutorial/sendVisitedStep', body);
      this.updateStorage(step);
    },

    handleResize() {
      this.window.width = window.innerWidth;
    },
  },
  beforeDestroy() {
    document.body.classList.remove('v-tour--active');
    this.$store.dispatch('onboardingTutorial/toggleTour', false);
  },
  watch: {
    tourActive() {
      this.setStepsAndStartTour();
    },
    '$store.state.onboardingTutorial.tourReset': {
      handler() {
        if (this.$store.state.onboardingTutorial.tourReset) {
          this.setStepsAndStartTour(); // Function to start your Vue Tour

          // Clear the tour reset flag to avoid repeat triggers
          this.$store.dispatch('onboardingTutorial/clearTourReset');
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.org-name {
  font-size: 20px;
  font-weight: 400;
}

.org-address {
  padding: 0;
}

.org-image {
  max-width: 100%;

  &__big {
    max-height: 250px;
  }

  &__small {
    max-height: 200px;
  }
}

.cursor-pointer {
  cursor: pointer;
}
</style>
